











































































































































































.evraz-summary-row {
  font-weight: bold;
}
